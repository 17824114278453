import { flowRight, get, set } from 'lodash/fp';
import { connect } from 'react-redux';
import { formValues } from 'redux-form';
import { branch, renderNothing } from 'recompose';
import { saveDataInvestor } from '../../actions';
import dataForm from '../dataForm';
import scrollToFirstError from '../form/scrollToFirstError';
import withRouter from '../withRouter';

const validTargets = ['client', 'partner'];

const requiredField = (value) => (!value ? 'Vous devez choisir une réponse.' : null);

const validators = {
    investmentTargets: (value) => ((!value || value.length < 3)
        ? 'Vous devez renseigner au moins trois objectifs.'
        : null),
    ...([
        'financialKnowledge',
        'legalKnowledge',
        'investmentKnowledge',
        'investmentPossession',
        'possibilityOfSouscription',
        'yesNoConductFinancialTransactionsWithoutAdvisor',
        'yesNoConductFinancialTransactionsWithAdvisor',
        'yesNoDelegatingPortfolio',
        'doneInvestmentTypes',
        'investmentInFinancialSupport',
        'yesNoTradingLossKnowledge',
        'downwardPrediction',
        'sufferTradingLosses',
        'doneReactionOnLoss',
        'supportLoss',
        'investmentStress',
        'availableSavings',
        'investmentTimeHorizon',
        'percentageInvestment',
        'investmentDescription',
        'favoriteEvolution',
        'lifeInsuranceSouscription',
        'lifeInsuranceDown',
        'lifeInsuranceLosses',
        'lifeInsuranceCauses',
        'diferenceFondEurosAndUnity',
        'drivenManagement',
        'drivenFree',
        'discretionaryManagement',
        'investmentPeriod',
        'objectBeneficiaryClause',
        'clauseOrModels',
        'yesNoDurableInvestment',
        'investmentRatio',
        'advisorTakesEnviromentalCriteria',
        'choicesEnvironmentalCriteria',
        'advisorTakeSocialCriteria',
        'choicesSocialCriteria',
        'advisorTakeGovernanceCriteria',
        'choicesGovernanceCriteria',
        'lowerPerformanceForESGInvestments',
    ].reduce((requiredValidators, field) => ({
        ...requiredValidators,
        [field]: requiredField,
    }), {})),
};

const validate = (values, { getFieldName }) => Object.entries(validators).reduce(
    (errors, [field, validator]) => {
        // get the real field name
        const name = getFieldName(field);
        // get the value for it
        const value = get(name, values);
        // run the validator
        const error = validator(value);

        // return an updated errors object
        return error ? set(name, error, errors) : errors;
    },
    {},
);

const formProvider = flowRight([
    withRouter,
    // deny invalid targets
    branch(({ params: { target = 'client' } }) => !validTargets.includes(target), renderNothing),
    // get the investor profile
    connect(({ investorProfile }, {
        params: { target = 'client' },
    }) => (({
        // provide the investorProfile in props
        investorProfile,
        // helper to get field names
        getFieldName: (name) => `investorProfiles.${target}.${name}`,
    }))),
    // set the data form (options can be provided)
    dataForm({
        autoSave: false,
        form: 'rip-investorProfile',
        id: ({ params: { id } }) => id,
        type: 'rip',
        // by default get back to the entry point on successful submits
        onSubmit: (result, dispatch, {
            getFieldName,
            navigate,
            investorProfile,
            params: { id, target = 'client' },
        }) => {
            const { investorProfiles } = result;
            // update the rip
            dispatch(saveDataInvestor('rip', id, {
                // update the rip with what we got
                investorProfiles,
                // but removed signatures
                signature: null,
                partnerSignature: null,
                advisorSignature: null,
                // ** suppression des signatures manuelles ET électroniques lors d'une modification de l'AMF
                universignTransaction: null,
                universignTransactionState: null,
                clientAndPartnerSignatureDate: null,
                // and uncheck legal requirements
                clientCollectInformationsAgreement: false,
                clientCorrectInformationsAgreement: false,
                clientCorrectInformationsShoyo: false,
                partnerCollectInformationsAgreement: false,
                partnerCorrectInformationsAgreement: false,
                partnerCorrectInformationsShoyo: false,
                advisorAgreement: false,
            }));

            if (get(getFieldName('decline'), result)) {
                // return to origin
                navigate(investorProfile.path);
            } else {
                // jump to the next page
                navigate(`/rip/${id}/investorProfile/${target}/result`);
            }
        },
        validate,
        // smooth scroll on errors
        onSubmitFail: scrollToFirstError({ containerId: 'investorProfileForm' }),
    }),
    // get the decline value
    formValues(({ getFieldName }) => ({
        decline: getFieldName('decline'),
        sufferTradingLosses: getFieldName('sufferTradingLosses'),
        lifeInsuranceLosses: getFieldName('lifeInsuranceLosses'),
        durableInvestment: getFieldName('yesNoDurableInvestment'),
        enviromentalCriteria: getFieldName('advisorTakesEnviromentalCriteria'),
        socialCriteria: getFieldName('advisorTakeSocialCriteria'),
        governanceCriteria: getFieldName('advisorTakeGovernanceCriteria'),
    })),
]);

export default formProvider;
