const isElectLatestActionSign = (infoUniversign, infoErip, user) => {
    if ('ready' === infoUniversign.status && 'manuellement' !== infoErip[user].type) {
        return true;
    }
    if (infoUniversign.date && infoErip[user]) {
        if (new Date(infoUniversign.date) >= new Date(infoErip[user].date)) {
            return true;
        }
    }

    return false;
};

export default isElectLatestActionSign;
