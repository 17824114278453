import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { InputField, IntegerField } from '../../form/field';
import { HorizontalLayout } from '../../form/layout';
import OwnerField from '../field/Owner';

const Dividends = ({ name, ripFromRecommendation }) => (
    <Fragment>
        <HorizontalLayout>
            <IntegerField name={`${name}.income`} title="Montant des dividendes" required />
            <InputField name={`${name}.recurring`} title="Récurrent" type="checkbox" />
            <OwnerField name={`${name}.beneficiary`} title="Bénéficiaire(s)" ripFromRecommendation={ripFromRecommendation} required />
        </HorizontalLayout>
        <div className="alert alert-warning no-margin-bottom margin-top-10">
            <p>
                Depuis le 01/01/2018, les dividendes sont soumis au PFU de 30 % avec acompte &agrave; la source et ne
                sont plus int&eacute;gr&eacute;s dans le calcul de l&apos;IR sauf option pour l&apos;int&eacute;gration
                &agrave; l&apos;imp&ocirc;t sur le revenu.
            </p>
        </div>
    </Fragment>
);

Dividends.propTypes = {
    name: PropTypes.string.isRequired,
    ripFromRecommendation: PropTypes.shape({}),
};

export default Dividends;
